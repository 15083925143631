import { useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { v4 as uuid } from 'uuid';
import axios from "axios";
import Cookies from 'universal-cookie'
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Declare columns that will be shown in the class table
const columns = [
  {
    id: 'school',
    label: 'School',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'teacher',
    label: 'Teacher',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'presenter',
    label: 'Presenter',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'eventTitle',
    label: 'Event Title',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'eventTime',
    label: 'Event Time',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Schedule',
    label: 'Schedule',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Increase size of table
          fontSize: '1.1rem',
        },
      },
    },
  },
});

// Function to create the table for presenter classes
export const PresenterEventsTable = (props) => {
  const navigate = useNavigate();

  // Declare constants
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const cookies = new Cookies();

  // Get all classes from the database then creates a row for each class
  async function getCourseData(){
    const headers = {
      'Authorization': 'Bearer ' + cookies.get('access'),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': "*/*" 
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/presenter-events/`,
        {headers: headers})
      .then((response) => {
        setRows(createData(response.data));
      })
  }

  const navigateSchedule = (i) =>{
    cookies.set('courseId', i, {path: '/'});
    navigate("/schedule");
  }

  // Creates a row for each class that will be shown in the table
  function createData(data){
    const rows = [];
    // For each array in data.course
    data.sets.forEach((event) => {
        let startTime = new Date(event.event_start).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', year: 'numeric' });
        let current = new Date();
        let yesterday = new Date(current.setDate(current.getDate() - 1));
        // If start time is less than a yesterday, show 'Completed'
        if (new Date(event.event_start) < yesterday){
            startTime = "Completed";
        }
        // Create a row for each class
      rows.push({
        school: event.school,
        teacher: event.teacher_names.join(", "),
        presenter: event.presenter_names.join(", "),
        eventTitle: event.event_title,
        eventTime: startTime,
        Schedule: 
          <IconButton onClick={() => navigateSchedule(event.course_id)}>
            <CalendarIcon />          
          </IconButton>
        })
        
    });
    rows.sort((a, b) => {
      // Completed comes after
      if (a.eventTime === "Completed"){
        return 1;
        }
        if (b.eventTime === "Completed"){
            return -1;
        }

      let dateA = new Date(a.eventTime);
      let dateB = new Date(b.eventTime);

      return dateA - dateB;

    });
    return rows;
  }

  // Refreshes the table when changes are made
  useEffect(() => {
    getCourseData();
  },[]); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    return (
      row.school.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.teacher.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.presenter.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.eventTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.eventTime.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div style={{margin: "10px"}}
      data-testid="presenter-class-table-1">
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }} >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
          <ThemeProvider theme={theme}>
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell
                    key = {uuid()}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            </ThemeProvider>
            <TableBody >
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key = {uuid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key = {uuid()} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}