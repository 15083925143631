import React, { useContext, useEffect } from "react";
import CreateEventButton from "./CreateEventButton";
import CommentButton from "./CommentButton";
import Cookies from "universal-cookie";
import CreateDefaultEventsButton from "./CreateDefaultEventsButton";
import DeleteAllEventButton from "./DeleteAllEventButton";
import GlobalContext from "./context/Context";
import EditPresenterModal from "./EditPresenterModal";
import { IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ClassInfoModal from "../Home/components/items/ClassInfoModalNew";
import { useNavigate } from "react-router-dom";

var courseId = "1";
// This is the side bar in the schedule page, which contains the button to create a new event
export default function Sidebar() {
  const cookies = new Cookies();
  const role = cookies.get("role");
  const {
    facilitator,
    teacher,
    presenters,
    school,
    grade,
    classroom,
    showEditPresenterModal,
    setShowEditPresenterModal,
    showClassInfoModal,
    setShowClassInfoModal,
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.get("courseId") === undefined) {
      navigate("/home");
    } else {
      courseId = cookies.get("courseId");
    }
  }, []);

  return (
    <aside data-testid="sidebar-1" className="border p-5 w-64">
      {showEditPresenterModal && <EditPresenterModal courseId={courseId} />}
      {showClassInfoModal && (
        <ClassInfoModal
          courseId={courseId}
          onClose={() => setShowClassInfoModal(false)}
        />
      )}
      {role === "ADMIN" ? (
        <div>
          <div style={{ marginBottom: "10px" }}>
            <CreateEventButton />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <CreateDefaultEventsButton />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <DeleteAllEventButton className="mt-8" />
          </div>
        </div>
      ) : (
        ""
      )}
      <CommentButton />
      <div>
        <div class="flex">
          <h1
            className="pt-3 border-0
                  text-black text-sm
                  font-semibold pb-2
                  w-4/5 
                  border-gray-200
                  focus:outline-none
                  focus:ring-0"
          >
            <span>{school}</span>
            <br />
            Grade: <span>{grade}</span>
            <br />
            Classroom: <span>{classroom}</span>
          </h1>

          {role === "ADMIN" ? (
            <IconButton
              style={{ alignSelf: "center" }}
              onClick={() => setShowClassInfoModal(true)}
            >
              <InfoIcon />
            </IconButton>
          ) : (
            ""
          )}
        </div>
        <h1
          className="pt-1 border-0 
              text-black text-sm
              font-semibold pb-2                                                 
              w-4/5 border-b-2 
              border-gray-200 
              focus:outline-none 
              focus:ring-0"
        >
          Facilitator: <br />
          <span className="pl-2" style={{ fontSize: "13px" }}>
            {"- " + facilitator}
          </span>
        </h1>
        <h1
          className="pt-1 border-0 
              text-black text-sm
              font-semibold pb-2                                                 
              w-4/5 border-b-2 
              border-gray-200 
              focus:outline-none 
              focus:ring-0"
        >
          Teacher: <br />
          <span style={{ fontSize: "13px" }}>
            {teacher
              .split(",")
              .filter((teacherName) => teacherName.trim() !== "") // Filter out empty entries
              .map((teacherName, index) => (
                <div key={index} className="pl-2">
                  {"- " + teacherName}
                </div>
              ))}
          </span>
        </h1>
        <div class="flex">
          <h1
            className="pt-1 border-0
                text-black text-sm
                font-semibold pb-2
                w-4/5
                border-gray-200
                focus:outline-none
                focus:ring-0"
          >
            Presenters:
            <span style={{ fontSize: "13px" }}>
              {presenters.map((presenter, index) => (
                <div key={index} className="pl-2">
                  {"- " + presenter}
                </div>
              ))}
            </span>
          </h1>
          {role === "ADMIN" ? (
            <IconButton
              style={{ alignSelf: "flex-start" }}
              onClick={() => setShowEditPresenterModal(true)}
            >
              <Edit />
            </IconButton>
          ) : (
            ""
          )}
        </div>
      </div>
    </aside>
  );
}
