import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "./context/Context";
import "react-datepicker/dist/react-datepicker.css";
import "./style_gridss.css";
import Multiselect from "multiselect-react-dropdown";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import Cookies from "universal-cookie";
import axios from "axios";

export default function EditPresenterModal({
  courseId
}) {
  // Get variables from the global context
  const {
    changes,
    updateChanges,
    presenters, // This is the string
    setShowEditPresenterModal,
  } = useContext(GlobalContext);
  const [presenterEmails, setPresenterEmails] = useState([]); // This is the options
  const [selectedPresenterEmails, setSelectedPresenterEmails] = useState([]); // This is selected
  const cookies = new Cookies();

  const role = cookies.get("role");

  const headers = {
    Authorization: "Bearer " + cookies.get("access"),
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/`, { headers })
      .then((res) => {
        const presenters_emails = res.data.sets
          .filter((el) => el.role === "PRESENTER")
          .map((a) => a.email);

        setPresenterEmails(presenters_emails);

        // Use presenters_emails directly to set selectedPresenterEmails
        setSelectedPresenterEmails(
          presenters_emails.filter((p) => presenters.toString().includes(p))
        );
      });
  }, [presenters]); // Only runs when presenters change

  // Submit the event to the calendar, currently saved to local storage
  // Supports updating and creating new events
  function handleSubmit(e) {
    e.preventDefault();
    setShowEditPresenterModal(false);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/courses/`,
        {
          id: courseId,
          presenters: selectedPresenterEmails,
        },
        { headers: headers }
      )
      .then((res) => {
        // Close the modal
        setShowEditPresenterModal(false);
        // Update the changes variable to force a re-render of the classes
        updateChanges(changes + 1);
      });
  }

  return (
    <div
      className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
      data-testid="event-modal-1"
      style={{ zIndex: "5" }}
    >
      <form className="bg-white rounded-lg shadow-2xl w-1/2">
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400">
            drag_handle
          </span>
          <div>
            <button onClick={() => setShowEditPresenterModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div>
          <div style={{ display: "flex", margin: "1rem 2rem 4rem 1.5rem" }}>
            <div
              className="gIconP"
              style={{ alignSelf: "center", marginRight: "1rem" }}
            >
              <span className="material-icons-outlined text-gray-400">
                <CoPresentOutlinedIcon />
              </span>
            </div>
            <div className="gPresenter" style={{}}>
              <Multiselect
                closeOnSelect={true}
                className="pt-3 border-0 
                    text-gray-600 text-l 
                    font-semibold pb-2 
                    border-gray-200 
                    focus:outline-none 
                    focus:ring-0
                    focus:border-blue-500"
                placeholder="Select Presenters (Optional)"
                isObject={false}
                options={presenterEmails}
                value={selectedPresenterEmails}
                selectedValues={selectedPresenterEmails}
                onSelect={(e) => setSelectedPresenterEmails(e)}
                onRemove={(e) => setSelectedPresenterEmails(e)}
                // style multiselect container
                style={{
                  closeOnSelect: true,
                  chips: {
                    background: "green",
                  },
                  // Remove outline from multiselect on focus
                  searchBox: {
                    outline: "none",
                  },
                }}
              />
            </div>
          </div>
          <div
            className="gSave"
            style={{ marginTop: "3rem", position: "relative" }}
          >
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
              style={{ position: "absolute", right: "30px", bottom: "10px" }}
            >
              {role === "ADMIN" ? "Save" : "Close"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
