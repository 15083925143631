import React, { useContext, useState, useEffect } from 'react'
import AdminContext from './../context/AdminContext'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import "./style.css";
import Cookies from 'universal-cookie';
import 'react-dropdown/style.css';
import 'react-tabs/style/react-tabs.css';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ExportStudentButton from './ExportStudentButton';
import CreateStudentButtonAdmin from './CreateStudentButtonAdmin';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledButton from '../../../../Components/StyledButton';

// Declare columns that will be shown in the student table
const columns = [
    {
        id: 'nameFirst',
        label: 'First Name',
        align: 'left',
        minWidth: 100
    },
    {
        id: 'nameLast',
        label: 'Last Name',
        align: 'left',
        minWidth: 100
    },
    {
        id: 'email',
        label: 'Email',
        align: 'left',
        minWidth: 100
    },
    {
        id: 'asn',
        label: 'ASN',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'id_release',
        label: 'ID Release',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'photos',
        label: 'Photos',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'video',
        label: 'Video',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'written_works',
        label: 'Written Works',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'voice',
        label: 'Voice',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'research',
        label: 'Research',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'emailAddress',
        label: 'Email Address',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'Edit',
        label: '',
        minWidth: 25,
        align: 'center',
        format: (value) => value.toFixed(2),
      },
    {
        id: 'Delete',
        label: '',
        minWidth: 25,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
];

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    // Increase size of table
                    fontSize: '1.1rem',
                },
            },
        },
    },
});

// Declare columns that will be shown in the accessibility table
const columnsAcc = [
    {
        id: 'description',
        label: 'Description',
        width: '60%',
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'isPresent',
        label: 'Present in Class',
        width: '10%',
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'Comments',
        label: 'Comments',
        width: '30%',
        align: 'center',
        format: (value) => value.toFixed(2),
    }
];

// Declare columns that will be shown in the Checklist table
const columnsCheck = [
    {
        id: 'description',
        label: 'Description',
        // absolute width 20%
        width: '60%',
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'isPresent',
        label: 'Done',
        width: '10%',
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'Comments',
        label: 'Comments',
        width: '30%',
        align: 'center',
        format: (value) => value.toFixed(2),
    }
];

// Lists that will hold values entered by the user
var accList = [];
var checkList = [];

// ClassInfoModal is the modal that pops up when the admin clicks the class information button
// It allows the admin to view the class information (students, accessibility, etc.)
export default function ClassInfoModal() {
    // Constants for managing student table  
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [teacherName, setTeacherName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [schoolDistrict, setSchoolDistrict] = useState("");
    const [schoolEmail, setSchoolEmail] = useState("");
    const [schoolPhone, setSchoolPhone] = useState("");
    const [preferredTheme, setPreferredTheme] = useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Constants for managing accessibility table
    const [rowsAcc, setRowsAcc] = useState([]);
    const [pageAcc, setPageAcc] = useState(0);
    const [rowsPerPageAcc, setRowsPerPageAcc] = useState(100);
    const handleChangePageAcc = (event, newPage) => {
        setPageAcc(newPage);
    };
    const handleChangeRowsPerPageAcc = (event) => {
        setRowsPerPageAcc(+event.target.value);
        setPageAcc(0);
    };

    // Constants for managing checklist table
    const [rowsCheck, setRowsCheck] = useState([]);
    const [pageCheck, setPageCheck] = useState(0);
    const [rowsPerPageCheck, setRowsPerPageCheck] = useState(100);
    const handleChangePageCheck = (event, newPage) => {
        setPageCheck(newPage);
    };
    const handleChangeRowsPerPageCheck = (event) => {
        setRowsPerPageCheck(+event.target.value);
        setPageCheck(0);
    };

    // Get variables from AdminContext
    const {
        setShowClassInfoModal,
        selectedCourseId,
        classInfoGrade,
        classInfoSchool,
        classInfoClassroom,
        classInfoTeacherId,
        changes,
        updateChanges,
        setFirstName,
        setLastName,
        setEmail,
        setASN,
        setIdRelease,
        setPhotos,
        setVideos,
        setWrittenWork,
        setVoice,
        setResearch,
        setEmailAddress,
        setEditStudent,
        setShowStudentModal,
        setSelectedStudentID
    } = useContext(AdminContext);

    // Header for axios requests
    const cookies = new Cookies();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('access'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': "*/*"
    }

    // Get data from database to populate tables
    async function getData() {
        // Get the class information as well as students in the class
        await axios
            .get(`${process.env.REACT_APP_API_URL}/courses/${selectedCourseId}`,
                { headers: headers })
            .then(res => {
                setRows(createData(res.data['students']));
            });
        await axios
            .get(`${process.env.REACT_APP_API_URL}/users/`,
                { headers: headers })
            .then(res => {
                console.log(classInfoTeacherId);
                let teacherString = ""
                for (let teacherId of classInfoTeacherId) {
                    var teacher = res.data.sets.filter(function (el) {
                        return el.id === teacherId;
                    });
                    if (teacherString != ""){
                        teacherString += ", ";
                    }
                    // If teacher first and last name are not null, set the teacher name
                    if (teacher[0].first_name !== null && teacher[0].last_name !== null) {
                        teacherString += teacher[0].first_name + " " + teacher[0].last_name;
                    } else {
                        teacherString += (teacher[0].email);
                    }
                    setTeacherName(teacherString);
                }
                // If school district is not null, set the school district
                if (teacher[0].school_district !== null) {
                    setSchoolDistrict(teacher[0].school_district);
                }
                // If school email is not null, set the school email
                if (teacher[0].school_mailing_address !== null) {
                    setSchoolEmail(teacher[0].school_mailing_address);
                }
                // If school phone is not null, set the school phone
                if (teacher[0].school_phone_number !== null) {
                    setSchoolPhone(teacher[0].school_phone_number);
                }
                // If preferred theme is not null, set the preferred theme
                if (teacher[0].theme_for_the_week !== null) {
                    setPreferredTheme(teacher[0].theme_for_the_week);
                }

            });

        // Get the accessibility information for the class
        await axios
            .get(`${process.env.REACT_APP_API_URL}/accessibility/${selectedCourseId}`,
                { headers: headers })
            .then((response) => {
                setRowsAcc(createAccData(response.data));
                accList = response.data.items;
            })

        // Get the checklist information for the class
        await axios
            .get(`${process.env.REACT_APP_API_URL}/checklist/${selectedCourseId}`,
                { headers: headers })
            .then((response) => {
                setRowsCheck(createCheckData(response.data));
                checkList = response.data.items;
            })
    }
    const handleEdit = (i) => {
        setFirstName(i.firstName);
        setLastName(i.lastName);
        setEmail(i.email);
        setASN(i.studentnumber);
        setIdRelease(i.idRelease);
        setPhotos(i.photos);
        setVideos(i.videos);
        setWrittenWork(i.writtenWork);
        setVoice(i.voice);
        setResearch(i.research);
        setEmailAddress(i.emailAddress);
        setSelectedStudentID(i.id);
        setEditStudent(true);
        setShowStudentModal(true);
      }
    
    // Retrieve data from database when the modal is opened
    useEffect(() => {
        getData();
    }, [changes]);
    // Remove student from database of the selected row
    const handleRemove = (i) => {
        if (window.confirm("Are you sure you want to delete " + i.firstName + " " + i.lastName + "?")) {
            axios
                .delete(`${process.env.REACT_APP_API_URL}/courses/${i.course}`,
                    { headers: headers, data: { id: i.id } })
                .then(res => {
                    updateChanges(changes + 1);
                });
        }
    }
    // Add students into rows to be shown in the table
    function createData(data) {
        const rows = [];
        var cId_release = "";
        var cPhotos = "";
        var cVideo = "";
        var cWritten_works = "";
        var cVoice = "";
        var cResearch = "";
        var cEmailAddress = "";

        // Loop through each student and add them to the rows
        // For each boolean field, loop through to show either a check or a cross
        for (let i = 0; i < data.length; i++) {
            if (data[i].idRelease === true) {
                cId_release = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cId_release = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].photos === true) {
                cPhotos = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cPhotos = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].videos === true) {
                cVideo = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cVideo = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].writtenWork === true) {
                cWritten_works = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cWritten_works = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].voice === true) {
                cVoice = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cVoice = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].research === true) {
                cResearch = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cResearch = <CloseIcon style={{ color: 'red' }} />;
            }
            if (data[i].emailAddress === true) {
                cEmailAddress = <CheckIcon style={{ color: 'green' }} />;
            } else {
                cEmailAddress = <CloseIcon style={{ color: 'red' }} />;
            }
            rows.push({
                nameFirst: data[i].firstName,
                nameLast: data[i].lastName,
                email: data[i].email,
                asn: String(data[i].studentnumber),
                id_release: cId_release,
                photos: cPhotos,
                video: cVideo,
                written_works: cWritten_works,
                voice: cVoice,
                research: cResearch,
                emailAddress: cEmailAddress,
                Edit: 
                <IconButton onClick={() => handleEdit(data[i])} aria-label="edit">
                  <EditIcon />
                </IconButton>,
                Delete:
                    <IconButton onClick={() => handleRemove(data[i])} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
            });
        }
        // Sort the rows by student first name
        rows.sort((a, b) => (a.nameFirst > b.nameFirst) ? 1 : -1);
        return rows;
    }

    // Add accessibility information into rows to be shown in the table
    function createAccData(data) {
        const rows = [];
        // For each item in the accessibility, create a row
        for (let i = 0; i < data.items.length; i++) {
            // Add row to the rows array
            rows.push({
                num: data.items[i].num,
                description: data.items[i].category,
                // Checkbox
                isPresent:
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            defaultChecked={data.items[i].checkbox}
                            onChange={(e) => {
                                accList[i].checkbox = e.target.checked;
                            }}
                        />
                    </div>,
                // Comment box
                Comments:
                    <div className="form-floating">
                        <textarea
                            className="form-control rounded-0 "
                            id="floatingTextarea2"
                            style={{ height: "100px", borderRadius: "5px", width: "100%" }}
                            placeholder="Comments"
                            defaultValue={data.items[i].detail}
                            onChange={
                                (e) => {
                                    accList[i].detail = e.target.value;
                                }
                            }
                        ></textarea>
                    </div>
            })
        }
        // Sort rows by num
        rows.sort((a, b) => (a.num > b.num) ? 1 : -1)
        return rows;
    }

    // Add checklist information into rows to be shown in the table
    function createCheckData(data) {
        const rows = [];
        // For each item in the checklist, create a row
        for (let i = 0; i < data.items.length; i++) {
            // Add row to the rows array
            rows.push({
                num: data.items[i].num,
                description: data.items[i].text,
                // Checkbox
                isPresent:
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            defaultChecked={data.items[i].detail}
                            onChange={(e) => {
                                checkList[i].detail = e.target.checked;
                            }}
                        />
                    </div>,
                // Comment box
                Comments:
                    <div className="form-floating">
                        <textarea
                            className="form-control rounded-0 "
                            id="floatingTextarea2"
                            style={{ height: "100px", borderRadius: "5px", width: "100%" }}
                            placeholder="Comments"
                            defaultValue={data.items[i].comments}
                            onChange={
                                (e) => {
                                    checkList[i].comments = e.target.value;
                                }
                            }
                        ></textarea>
                    </div>
            });
        }
        // Sort rows by num
        rows.sort((a, b) => (a.num > b.num) ? 1 : -1)
        return rows;
    }

    // Update checklist items when user submits the checklist form
    function handleSaveCheckList(e) {
        e.preventDefault();

        let promises = checkList.map((item) => {
            let data = {
                "num": item.num,
                "comments": item.comments,
                "detail": item.detail
            }
            return axios
                .patch(`${process.env.REACT_APP_API_URL}/checklist/${selectedCourseId}`,
                    data,
                    { headers: headers })
        });

        Promise.all(promises).then((response) => {
            getData();
            alert("Saved!");
        });
    }



    // Update accessibility items when user submits the accessibility form
    function handleSaveAccessibility(e) {
        e.preventDefault();

        let promises = accList.map((item) => {
            let data = {
                "num": item.num,
                "detail": item.detail,
                "checkbox": item.checkbox
            }
            return axios
                .patch(`${process.env.REACT_APP_API_URL}/accessibility/${selectedCourseId}`,
                    data,
                    { headers: headers })
        });

        Promise.all(promises).then((response) => {
            getData();
            alert("Saved!");
        });
    }

    return (
        <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center"
            data-testid="class-info-modal-1"
            style={{ zIndex: '5' }}>
            <div className="bg-white rounded-lg shadow-2xl w-2/3" >
                <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                    <span className="text-gray-700 font-semibold">
                        {
                            classInfoSchool +

                            " \xa0\xa0\xa0\xa0\xa0\xa0Grade: " +
                            classInfoGrade +

                            " \xa0\xa0\xa0\xa0\xa0\xa0Classroom: " +
                            classInfoClassroom +

                            " \xa0\xa0\xa0\xa0\xa0\xa0Teacher: " +
                            teacherName +

                            " \xa0\xa0\xa0\xa0\xa0\xa0District: " +
                            schoolDistrict +

                            //" \xa0\xa0\xa0\xa0\xa0\xa0School Email: " +
                            //schoolEmail +

                            //" \xa0\xa0\xa0\xa0\xa0\xa0School Phone: " +
                            //schoolPhone +

                            " \xa0\xa0\xa0\xa0\xa0\xa0Preferred Theme: " +
                            preferredTheme

                        }
                    </span>
                    <div>
                        <button onClick={() => setShowClassInfoModal(false)}>
                            <span className="material-icons-outlined text-gray-400">
                                close
                            </span>
                        </button>
                    </div>
                </header>
                <div className="px-4 py-2" data-testid="class-info-modal-2">
                    <Tabs>
                        <TabList>
                            <Tab>Students</Tab>
                            <Tab>Checklist</Tab>
                            <Tab>Accessibility</Tab>
                        </TabList>

                        <TabPanel>
                            <div style={{ margin: "10px" }}>
                                <div className="class-information">
                                    <span className="School Contact">
                                        <div>
                                            <span className="text-gray-700 font-semibold">
                                                School Phone: {schoolPhone}
                                                <span style={{ float: "right", textAlign: "left" }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                                        <CreateStudentButtonAdmin />
                                                        <ExportStudentButton 
                                                            courseId={selectedCourseId}
                                                        />
                                                        <StyledButton 
                                                            Icon={ClearIcon}
                                                            label="Clear Info"
                                                            color="red"
                                                            showConfirmationModal={true}
                                                            confirmModalDescription="Would you like to delete data for this class?\n(Removes all students and reset the accessibilitiy and checklist)"
                                                            onClick={() => {
                                                                axios
                                                                .delete(`${process.env.REACT_APP_API_URL}/courses/`,
                                                                  {headers: headers, data: {id: selectedCourseId, deleteInfoOnly: true}})
                                                                .then(() => {
                                                                  updateChanges(changes + 1);
                                                                })
                                                            }}
                                                        />
                                                    </div>

                                                </span>
                                                <br />
                                                School Email: {schoolEmail}
                                            </span>

                                        </div>

                                    </span>
                                </div>

                                <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                    <TableContainer
                                        sx={{
                                            border: "4px solid rgba(0,0,0,0)",
                                            padding: 1,
                                            height: "60vh",
                                            margin: "auto",
                                        }}
                                    >
                                        <Table stickyHeader aria-label="sticky table">
                                            <ThemeProvider theme={theme}>
                                                <TableHead>
                                                    <TableRow >
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={uuid()}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            </ThemeProvider>
                                            <TableBody >
                                                {rows
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={uuid()}>
                                                                {columns.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell key={uuid()} align={column.align}>
                                                                            {column.format && typeof value === 'number'
                                                                                ? column.format(value)
                                                                                : value}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <form>
                                <div>
                                    <div style={{ margin: "10px" }}>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding: 1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}
                                            >
                                                <Table stickyHeader aria-label="sticky table">
                                                    <ThemeProvider theme={theme}>

                                                        <TableHead>
                                                            <TableRow >
                                                                {columnsCheck.map((column) => (
                                                                    <TableCell
                                                                        key={uuid()}
                                                                        align={column.align}
                                                                        style={{ minWidth: column.minWidth }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    </ThemeProvider>
                                                    <TableBody >
                                                        {rowsCheck
                                                            .slice(pageCheck * rowsPerPageCheck, pageCheck * rowsPerPageCheck + rowsPerPageCheck)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={uuid()}>
                                                                        {columnsCheck.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={uuid()} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component="div"
                                                count={rowsCheck.length}
                                                rowsPerPage={rowsPerPageCheck}
                                                page={pageCheck}
                                                onPageChange={handleChangePageCheck}
                                                onRowsPerPageChange={handleChangeRowsPerPageCheck}
                                            />
                                        </Paper>
                                        <button type="submit"
                                            onClick={handleSaveCheckList}
                                            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                            style={{ margin: '10px', marginBottom: '5px', float: 'right' }}>
                                            Save Checklist
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <form>
                                <div>
                                    <div style={{ margin: "10px" }}>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                            <TableContainer
                                                sx={{
                                                    border: "4px solid rgba(0,0,0,0)",
                                                    padding: 1,
                                                    height: "60vh",
                                                    margin: "auto",
                                                }}
                                            >
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow >
                                                            {columnsAcc.map((column) => (
                                                                <TableCell
                                                                    key={uuid()}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody >
                                                        {rowsAcc
                                                            .slice(pageAcc * rowsPerPageAcc, pageAcc * rowsPerPageAcc + rowsPerPageAcc)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={uuid()}>
                                                                        {columnsAcc.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={uuid()} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component="div"
                                                count={rowsAcc.length}
                                                rowsPerPage={rowsPerPageAcc}
                                                page={pageAcc}
                                                onPageChange={handleChangePageAcc}
                                                onRowsPerPageChange={handleChangeRowsPerPageAcc}
                                            />
                                        </Paper>
                                    </div>
                                    <button type="submit"
                                        onClick={handleSaveAccessibility}
                                        className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                                        style={{ margin: '10px', marginBottom: '5px', float: 'right' }}>
                                        Save Accessibility
                                    </button>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
