import React from 'react'

// Context for the schedule
// create context with variables shared between components
const GlobalContext = React.createContext({
    showEventModal: false,
    setShowEventModal: () => {},
    showCommentModal: false,
    setShowCommentModal: () => {},
    showClassInfoModal: false,
    setShowClassInfoModal: () => {},
    showEditPresenterModal: false,
    setShowEditPresenterModal: () => {},
    showConfirmModal: false,
    setShowConfirmModal: () => {},
    showDeleteModal: false,
    setShowDeleteModal: () => {},
    dispatchCalEvent: ({type, payload}) => {},
    savedEvents: [],
    selectedEvent: null,
    setSelectedEvent: () => {},
    changes: 0,
    updateChanges: () => {},

    monday: new Date(),
    setMonday: () => {},
    tuesday: new Date(),
    setTuesday: () => {},
    wednesday: new Date(),
    setWednesday: () => {},
    thursday: new Date(),
    setThursday: () => {},
    friday: new Date(),
    setFriday: () => {},

    // For displaying info in side bar
    facilitator: "",
    setFacilitator: () => {},
    teacher: "",
    setTeacher: () => {},
    presenters: [],
    setPresenters: () => {},
    school: "",
    setSchool: () => {},
    grade: "",
    setGrade: () => {},
    classroom: "",
    setClassroom: () => {},
});

export default GlobalContext