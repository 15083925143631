import React, {useState, useReducer, useEffect} from 'react'
import GlobalContext from './Context'
// Provides the context for the schedule

// Reducer that handles the adding, updating, and deleting of events
function savedEventsReducer(state, {type, payload}){
  switch(type){
    case 'push':
      return [...state, payload];
    case 'update':
      return state.map(evt => evt.id === payload.id ? payload : evt);
    case 'delete':
      return state.filter(evt => evt.id !== payload.id);
    default:
      throw new Error();
  }
}

// Get the saved events from local storage
function initEvents(){
  const storageEvents = localStorage.getItem("savedEvents")
  const parseEvents = storageEvents ? JSON.parse(storageEvents) : [];
  parseEvents.map((i) => i.start = new Date(i.start));
  parseEvents.map((i) => i.end = new Date(i.end));
  return parseEvents;
}


export default function ContextWrapper(props) {
    const [showEventModal, setShowEventModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showClassInfoModal, setShowClassInfoModal] = useState(false);
    const [showEditPresenterModal, setShowEditPresenterModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [changes, updateChanges] = useState(0);
    const [savedEvents, dispatchCalEvent] = useReducer(savedEventsReducer, [], initEvents);
    const [monday, setMonday] = useState(new Date());
    const [tuesday, setTuesday] = useState(new Date());
    const [wednesday, setWednesday] = useState(new Date());
    const [thursday, setThursday] = useState(new Date());
    const [friday, setFriday] = useState(new Date());
    // For displaying info in side bar
    const [facilitator, setFacilitator] = useState("");
    const [teacher, setTeacher] = useState("");
    const [presenters, setPresenters] = useState([]);
    const [school, setSchool] = useState("");
    const [grade, setGrade] = useState("");
    const [classroom, setClassroom] = useState("");

    // Save the events to local storage when they change
    useEffect(() => {
      localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
    }, [savedEvents]);

    // When the modal is closed, clear the selected event
    useEffect(() => {
      if (!showEventModal) {
        setSelectedEvent(null);
      }
    }, [showEventModal]);

    // Context for the schedule
  return (
    <GlobalContext.Provider
        value={{
            changes,
            updateChanges,
            showEventModal,
            setShowEventModal,
            showConfirmModal,
            setShowConfirmModal,
            showClassInfoModal,
            setShowClassInfoModal,
            showEditPresenterModal,
            setShowEditPresenterModal,
            showCommentModal,
            setShowCommentModal,
            dispatchCalEvent,
            savedEvents,
            selectedEvent,
            setSelectedEvent,
            monday,
            setMonday,
            tuesday,
            setTuesday,
            wednesday,
            setWednesday,
            thursday,
            setThursday,
            friday,
            setFriday,
            facilitator,
            setFacilitator,
            teacher,
            setTeacher,
            presenters,
            setPresenters,
            school,
            setSchool,
            classroom,
            setClassroom,
            grade,
            setGrade,
            showDeleteModal,
            setShowDeleteModal,
        }}
        >
        {props.children}
    </GlobalContext.Provider>
  )
}
